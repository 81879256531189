import './photoalbum.scss';
import '../../web/glightbox/glightbox.min.css';

import * as dompack from 'dompack';
import GLightbox from '../../web/glightbox/glightbox.min.js';

dompack.onDomReady(() => {
  dompack.qSA('.widget--photoalbum').forEach((widget) => {
    if (widget.classList.contains('ww-lightbox-initialized')) {
      return;
    }

    const widgetId = widget.getAttribute('data-widgetid');
    GLightbox({ selector: `.glightbox-${widgetId}` });
    GLightbox({ selector: `.glightbox-mobile-${widgetId}` });

    widget.querySelectorAll('.masonry-grid-w-photoalbum').forEach((grid) => {
      const msnry = new Masonry(grid, {
        itemSelector: '.masonry-grid-w-photoalbum__grid-item',
        columnWidth: '.masonry-grid-w-photoalbum__grid-sizer',
        gutter: '.masonry-grid-w-photoalbum__gutter-sizer',
        percentPosition: true,
        gutter: 10,
      });
      imagesLoaded(grid).on('progress', function () {
        msnry.layout();
      });
    });

    widget.classList.add('ww-lightbox-initialized');
  });

  // FIXME: more unique class names
});
