/* Custom template */

import * as dompack from 'dompack';
import 'dompack/browserfix/reset.css';
import '@mod-system/js/wh/integration';
import '@mod-publisher/js/richcontent/all';
import '@mod-publisher/js/analytics/ga4';
import * as whintegration from '@mod-system/js/wh/integration';
import { WWBasicSite, WWForms } from '@mod-ww/webdesigns/template/ww';

import { openLinksInNewWindow } from '@mod-publisher/js/linkhandler';
openLinksInNewWindow();

import PageOverview from '@mod-ww/pages/overview/overview';
dompack.register('.ww-p-overview', (node) => new PageOverview(node));

import './tit.scss';
import '@splidejs/splide/css';
import $ from 'cash-dom';

// components
import './widgets/widgets';
import './footer/footer';
import './header/header';

// custom components (Lit)
import './components/selectcategory/selectcategory';

// pages
import './pages/blog/blog';
import './pages/category/category';
import './pages/home/home';
import './pages/partners/partners';

// don't wait too long to show the page
if (document.documentElement.classList.contains('haspageloader')) {
  window.setTimeout(() => {
    document.documentElement.classList.add('init');
  }, 500);
}

dompack.onDomReady(() => {
  $('.navbar-brand').append(
    `<img src="${whintegration.config.site.logo_on_white}" alt="${whintegration.config.site.sitename}" class="header-img-onwhite" />`
  );

  $('.offcanvas .offcanvas-title')
    .html('')
    .append(
      `<img src="${whintegration.config.site.logo}" alt="${whintegration.config.site.sitename}" class="offcanvas-title__img" />`
    );

  // if (!document.documentElement.classList.contains('nocontainer')) {
  //   dompack
  //     .qSA(
  //       `main > *:not(.widget):not(.container):not(.wh-rtd), .wh-rtd > *:not(.widget):not(.container), main > .widget--button.widget--site, .wh-rtd > .widget--button.widget--site`
  //     )
  //     .forEach((el) => {
  //       wrapElementInContainer(el);
  //     });
  // }

  // if (!!whintegration.config.islive) {
  //   document.addEventListener('contextmenu', function (e) {
  //     e.preventDefault();
  //     alert(`Oeps! Op de inhoud van deze website rust auteursrecht. Neem contact met ons op voor meer informatie.`);
  //   });
  // }

  new WWBasicSite({});
  new WWForms({ convertButtonsToBootstrapButtons: true, bootstrapButtonClasses: ['btn', 'btn-primary'] });
  replaceSiteRegionInstances();

  // document.documentElement.classList.add('init');
});

function wrapElementInContainer(el, className = 'container-xl') {
  if (!el) {
    return;
  }
  const wrapper = document.createElement('div');
  wrapper.className = className;
  el.parentNode.insertBefore(wrapper, el);
  wrapper.appendChild(el);
  return wrapper;
}

export function replaceSiteRegionInstances() {
  $('.js-convert-site-region').each((idx, el) => {
    $(el).html(replaceSiteRegion($(el).html()));
  });
}

export function replaceSiteRegion(txt) {
  if (!txt) {
    console.error('No text in param');
    return 'Twente';
  }

  const replaceWith = whintegration.config.site.site_region || 'Twente';

  // replace 'Twente' with the object type title
  if (txt.includes('Twente')) {
    // console.log(`Replacing 'voertuig' with '${replaceWith}'`);
    return txt.replaceAll('Twente', replaceWith);
  }

  // if (txt.includes('[tag]')) {
  //   // console.log(`Replacing '[tag]' with '${replaceWith}'`);
  //   return txt.replaceAll('[tag]', replaceWith);
  // }

  // console.log('Nothing to replace');
  return txt;
}
