import * as dompack from 'dompack';
import * as cookie from 'dompack/extra/cookie';
import * as whintegration from '@mod-system/js/wh/integration';
import * as consenthandler from '@mod-publisher/js/analytics/consenthandler';
import * as gtm from '@mod-publisher/js/analytics/gtm';

export default class MSOCookieWall {
  constructor({
    /* option1 = '' */
  } = {}) {
    this.cookieWallNode = dompack.qS('.ww-cookiewall');
    if (!this.cookieWallNode) {
      throw 'missing element .ww-cookiewall; did you add [cookiewall] to the Witty file?';
    }

    this.cookieWallContainer = dompack.qS('.ww-cookiewall__container');
    if (!this.cookieWallContainer) {
      throw 'missing element .ww-cookiewall__container; did you add [cookiewall] to the Witty file?';
    }

    this.cookieWallName = whintegration.config.site['ww-cookiewallname'];
    if (!this.cookieWallName) {
      console.error(whintegration.config);
      throw 'cookiewall: no cookie name set';
    }

    this.acceptButtons = dompack.qSA(this.cookieWallNode, '.ww-cookiewall__button--accept');
    if (!this.acceptButtons.length) {
      throw 'cookiewall: no accept button (.ww-cookiewall__button--accept)';
    }

    this.saveButton = dompack.qS(this.cookieWallNode, '.ww-cookiewall__link--save');

    if (location.href.includes('resetcookiewall')) {
      cookie.remove(this.cookieWallName);

      const url = new URL(location);
      url.searchParams.delete('resetcookiewall');
      location.href = url.href;
    }

    this._setupButtonListeners();
    this._setup();
  }

  _allowVideo() {
    return consenthandler.hasConsent('marketing') || consenthandler.hasConsent('all');
  }

  _activateAllVideos() {
    for (const overlay of dompack.qSA('.wh-requireconsent__overlay')) {
      overlay.hidden = true;
    }

    for (const el of dompack.qSA('.wh-video--activate')) {
      el.click();
    }
  }

  // returns 'acceptdecline' or 'specific'
  getCookieWallType() {
    const type = whintegration.config.site['ww-cookiewalltype'];
    if (!['acceptdecline', 'specific'].includes(type)) throw `Unknown cookie wall type "${type}"`;

    return type;
  }

  addConsentOverlayIfNeeded({ title = '', editSettingsButtonTitle = 'Aanpassen' } = {}) {
    if (this.getCookieWallType() == 'acceptdecline') {
      if (this._allowVideo()) {
        this._activateAllVideos();
        return;
      }
    }

    for (const overlay of dompack.qSA('.wh-requireconsent__overlay')) {
      let addHTML = `<div class="ww-cookiewall__consent-container">
           <p class="ww-cookiewall__consent-text">
             ${title}
           </p>
           <div class="ww-cookiewall__consent-button-container">
             <a class="button ww-cookiewall__consent-button js-ww-cookiewall-open-cookie-prefs" href="#">${editSettingsButtonTitle}</a>
           </div>
         </div>`;

      overlay.insertAdjacentHTML('beforeend', addHTML);
    }
    this._setupButtonListeners();
  }

  _setup() {
    if (whintegration.config.site['ww-cookiewall-loadimmediately'] == false) {
      return this._setupAfterCheck();
    }

    try {
      gtm.init();
    } catch (e) {
      console.error('Could not initialize consent', e);
    }

    if (location.href.includes('noconsentpopup')) {
      return;
    }

    consenthandler.setup(`${this.cookieWallName}`, () => this._setupCookieWall());

    const consent = dompack.qS('html').dataset.whConsent ?? null;
    if (consent) {
      consent.split(' ').forEach((pref) => {
        if (pref === 'necessary') {
          return;
        }

        const target = dompack.qS(`#gdpr-cookietype-${pref}`);
        if (target) {
          target.checked = true;
        }
      });
    }
  }

  // setups the page in the situation we only load GTM if accepted
  _setupAfterCheck() {
    _log(`_setupAfterCheck()`);
    try {
      // setup consent handler so we can check the current consent
      consenthandler.setup(`${this.cookieWallName}`, () => this._setupCookieWall());
      _log(`cookiewalltype = "${this.getCookieWallType()}"`);

      if (this.getCookieWallType() == 'acceptdecline') {
        const currentConsent = document.documentElement.dataset.whConsent;
        _log(`consent set to: "${currentConsent}"`);

        if (currentConsent === 'denied') {
          _log(`consent denied, don't load GTM, we're done`);
          return;
        } else if (currentConsent === 'unknown') {
          _log(`consent not set yet, don't load GTM, we're done`);
          return;
        }
      }

      gtm.initOnConsent();
    } catch (e) {
      console.error('Could not initialize consent', e);
    }
  }

  _setupCookieWall() {
    this._toggleCookieWallVisibility(true);
  }

  _acceptAllCookies(evt) {
    this._handleSaveCookiesEvent(evt, ['all', 'marketing']); // also add 'marketing' so we can use that for default YouTube vids
    this._activateAllVideos();
  }

  _setupButtonListeners() {
    if (this.getCookieWallType() == 'acceptdecline') {
      // accept button: set consent to 'all'
      this.acceptButtons.forEach((btn) => {
        btn.addEventListener('click', (evt) => {
          this._acceptAllCookies(evt);
        });
      });

      // this type needs a decline button, it sets consent to an empty []
      const declineButton = dompack.qS(this.cookieWallNode, '.ww-cookiewall__button--decline');
      if (!declineButton) {
        throw 'cookiewall: no decline button (.ww-cookiewall__button--decline)';
      }

      declineButton.addEventListener('click', (evt) => {
        this._handleSaveCookiesEvent(evt, []); // set empty
      });
    } else {
      // type == specific
      // accept button: set consent to checked options
      this.acceptButtons.forEach((btn) => {
        btn.addEventListener('click', (evt) => {
          let prefs = [];
          document.getElementsByName('gdpr[]').forEach(function (field) {
            prefs.push(field.value);
          });

          this._handleSaveCookiesEvent(evt, prefs);
        });
      });

      if (this.saveButton) {
        this.saveButton.addEventListener('click', (evt) => {
          let prefs = [];
          document.getElementsByName('gdpr[]').forEach(function (field) {
            if (field.checked) {
              prefs.push(field.value);
            }
          });

          this._handleSaveCookiesEvent(evt, prefs);
        });
      }

      const elGdprCookieCheck = document.getElementById('gdpr-cookie-check');
      if (elGdprCookieCheck) {
        elGdprCookieCheck.addEventListener('click', function (e) {
          document.getElementById('gdpr-cookie-message').style.display = 'none';
          document.getElementById('gdpr-cookie-types').style.display = 'block';
        });
      }

      const elGdprCookieCheckBack = document.getElementById('gdpr-cookie-check-back');
      if (elGdprCookieCheckBack) {
        elGdprCookieCheckBack.addEventListener('click', function (e) {
          document.getElementById('gdpr-cookie-message').style.display = 'block';
          document.getElementById('gdpr-cookie-types').style.display = 'none';
        });
      }
    }

    for (const openPrefs of dompack.qSA('.js-ww-cookiewall-open-cookie-prefs')) {
      openPrefs.addEventListener('click', (evt) => {
        dompack.stop(evt);

        if (this.getCookieWallType() == 'acceptdecline') {
          this._acceptAllCookies(evt);
          return;
        }

        this._toggleCookieWallVisibility(true);
      });
    }
  }

  _handleSaveCookiesEvent(evt, consent = []) {
    dompack.stop(evt);
    consenthandler.setConsent(consent);
    this._toggleCookieWallVisibility(false);
  }

  _toggleCookieWallVisibility(show = false) {
    console.log('toggle', show);
    dompack.toggleClass(this.cookieWallNode, 'show', show === true);
    dompack.toggleClass(document.documentElement, 'show-ww-cookiewall', show === true);

    dompack.toggleClass(this.cookieWallContainer, 'show', show === true);
    dompack.toggleClass(document.documentElement, 'show-ww-cookiewall', show === true);

    // extra classes may be useful for some sites
    dompack.toggleClass(document.documentElement, 'hide-ww-cookiewall', show === false);
    dompack.toggleClass(this.cookieWallNode, 'hide', show !== true);

    dompack.toggleClass(document.documentElement, 'hide-ww-cookiewall', show === false);
    dompack.toggleClass(this.cookieWallContainer, 'hide', show !== true);
  }
}

function _log(...args) {
  if (dompack.debugflags.cst) {
    console.log('[cst-ww]', ...args);
  }
}
