/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

/* global $, Sticky */

import './blog.scss';

import * as whintegration from '@mod-system/js/wh/integration';
import * as dompack from 'dompack';
// import * as rpc from "./blog.rpc.json";
// import PageNav from './pagenav';

// import './quote/quote.scss';

dompack.onDomReady(() => {
  // if (document.documentElement.classList.contains('page-blog-index')) {
  //   setupIndex();
  // }

  // if (document.documentElement.classList.contains('page-blog-details')) {
  //   const isPreviewWindow = window.parent && window.parent !== window;
  //   if (isPreviewWindow) {
  //     console.info('Is preview window, no need to run page functions');
  //     return;
  //   }

  //   setupDetails();
  //   setupSwiper();
  // }
});

// function setupIndex() {
//   $('.js-filter-on-category').change(function() {
//     location.href = $(this).val();
//   });

//   new PageNav();
//   new Sticky('.p-blogindex__licenseform');
// }

// async function setupDetails() {
//   // save view after 5 seconds
//   // window.setTimeout(async() => {
//   //   await rpc.addView(whintegration.config.obj.fileid);
//   // }, 5000);

//   $('.js-share-twitter').click(function(evt) { shareEvent(evt, 'twitter'); });
//   $('.js-share-facebook').click(function(evt) { shareEvent(evt, 'facebook'); });
//   $('.js-share-linkedin').click(function(evt) { shareEvent(evt, 'linkedin'); });
//   $('.js-share-googleplus').click(function(evt) { shareEvent(evt, 'gplus'); });
//   $('.js-share-mail').click(function(evt) { shareEvent(null, 'mail'); });

//   new Sticky('.p-blogindex__licenseform');
// }

// function shareEvent(evt, type) {
//   if (evt)
//     evt.preventDefault();

//   switch(type) {
//     case 'twitter':
//       Dialog.twitter(location.href, whintegration.config.obj.filetitle).open();
//       break;
//     case 'facebook':
//       Dialog.facebook(location.href,).open();
//       break;
//     case 'linkedin':
//       Dialog.linkedIn(location.href, whintegration.config.obj.filetitle).open();
//       break;
//     case 'gplus':
//       Dialog.gplus(location.href,).open();
//       break;
//     case 'mail':
//       break; // only addShare
//   }

//   // rpc.addShare(whintegration.config.obj.fileid);
// }

// function setupSwiper(){
//   //TODO: fix swiper blogs
//   let relatedBlogs = document.getElementsByClassName("c-related__blogs")[0]
//   if (window.innerWidth < 900) {
//     let swiper;

//     swiper = new Swiper(".related-swiper", {
//       direction: 'horizontal',
//       loop: false,
//       speed: 300,
//       spaceBetween: 0,
//       slidesPerView: 'auto',
//       grabCursor: true,
//       pagination: {
//         el: '.swiper-pagination',
//       },
//     });
//   }else{
//     relatedBlogs.classList.add("swiper-disable")
//   }
// }
