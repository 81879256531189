import './category.scss';

import * as dompack from 'dompack';
import { Splide } from '@splidejs/splide';
import * as whintegration from '@mod-system/js/wh/integration';
import $ from 'cash-dom';

dompack.onDomReady(() => {
  if (!document.documentElement.classList.contains('page-category')) {
    return;
  }

  if (document.body.getBoundingClientRect().width >= 768) {
    $('details').attr('open', 'open');
  }

  $('#category-detailstextimage').prependTo('#category-detailstext > *:first-child');

  const slider = new Splide(dompack.qS('#categories-carousel'), {
    fixedWidth: 120,
    arrows: true,
    type: 'loop',
    pagination: false,
    gap: '3rem',
    start: whintegration.config.obj.selectedcategoryidx,
  });
  slider.mount();
  setTimeout(() => {
    document.documentElement.classList.add('init');
  }, 200);
});
