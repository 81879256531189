/* global gsap, Draggable, TweenLite */
import './categoryslider.scss';

import * as dompack from 'dompack';

import { Splide } from '@splidejs/splide';
import $ from 'cash-dom';

class Slider {
  constructor(el) {
    this.el = el;
    this.thumbs = new Splide(el.querySelector('.js-thumbnail-carousel'), {
      fixedWidth: 120,
      rewind: true,
      arrows: false,
      focus: 'center',
      drag: 'free',
      pagination: false,
      updateOnMove: true,
      isNavigation: true,
      gap: '3rem',
      // focus: 'center',
      // breakpoints: {
      //   // 600px and lower
      //   600: {
      //     fixedWidth: 120,
      //     // fixedHeight: 44,
      //   },
      // },
    });

    this.thumbs.on('move', (newIndex, prevIndex, destIndex) => {
      if (this.isDragging) {
        return;
      }

      const moveToPerc = destIndex / 15;
      const draggableObject = Draggable.get(el.querySelector('.js-skull-object'));
      const moveTo = draggableObject.maxX * moveToPerc;
      TweenLite.to(el.querySelector('.js-skull-object'), { x: moveTo });
    });

    this.main = new Splide(el.querySelector('.js-main-carousel'), {
      type: 'fade',
      rewind: true,
      pagination: false,
      arrows: false,
      drag: false,
    });
    this.main.sync(this.thumbs);
    this.main.mount();
    this.thumbs.mount();

    let s;
    this.skullSlider = {
      settings: {
        $sliderContainer: el.querySelector('.js-skull-slider-wrapper'),
        $skull: el.querySelector('.js-skull-object'),
        draggableObject: {},
        draggableClickTl: {},
        draggableDragTl: {},
      },

      init: function () {
        s = this.settings;
      },

      draggable: (tween) => {
        // console.log('🚀 ~ file: categoryslider.es:120 ~ dompack.qSA ~ s.$skull:', s.$skull);
        s.draggableObject = Draggable.create(s.$skull, {
          type: 'x',
          edgeResistance: 1,
          bounds: s.$sliderContainer,
          lockAxis: true,
          onDrag: () => {
            const draggableObject = this.getDraggableObject();
            const pos = draggableObject.x / draggableObject.maxX;
            // console.log('🚀 ~ file: categoryslider.es:132 ~ Slider ~ constructor ~ pos:', pos);
            this.thumbs.go(parseInt(pos * this.thumbs.length));
          },
        });
        s.draggableObject[0].addEventListener('dragstart', () => (this.isDragging = true));
        s.draggableObject[0].addEventListener('dragend', () => (this.isDragging = false));
      },
    };

    this.skullSlider.init();
    this.skullSlider.draggable();
  }

  getDraggableObject() {
    return Draggable.get(this.el.querySelector('.js-skull-object'));
  }
}

dompack.onDomReady(() => {
  dompack.qSA('.w-categoryslider').forEach((el) => {
    new Slider(el);
    $('.js-main-carousel-slide').each((index, _slide) => {
      const title = _slide.dataset.title;
      const $text = $(_slide).find('.w-textimage__text');
      $text.prepend(`<div class="w-categoryslider__slide-title cera-pro mb-3">${title}</div>`);
      $text.append(
        `<a class="btn btn-outline-primary mt-4" href="${_slide.dataset.link}">ga naar ${title.toLowerCase()}</a>`
      );
    });
  });
});
