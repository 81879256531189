/* global AccessibleMenu, Mmenu, $ */

import * as dompack from 'dompack';
import * as whintegration from '@mod-system/js/wh/integration';
import MobileMenu from '@mod-ww/components/mobilemenu/mobilemenu';

dompack.onDomReady(() => {
  if (whintegration.config.site.module_mmenu === true) {
    initializeMMenu();
  } else {
    new MobileMenu();
    for (const el of dompack.qSA('.ww-mobilemenu')) {
      el.classList.add('init');
    }
  }

  if (typeof AccessibleMenu !== 'undefined') {
    initializeAccessibleMenu();
  }
});

function initializeAccessibleMenu() {
  const navs = document.querySelectorAll('nav');
  const menuSettings = {
    menuItemSelector: '.ww-menu-item',
    submenuItemSelector: '.ww-menu-item.ww-dropdown',
    submenuToggleSelector: '.ww-dropdown-toggle',
    submenuSelector: '.ww-menu.ww-dropdown',
  };

  //init language dropdown
  const langMenu = dompack.qS('.ww-languagemenu');
  if (langMenu) {
    new AccessibleMenu.DisclosureMenu({
      menuElement: langMenu,
      ...menuSettings,
      isHoverable: true,
    });
  }

  navs.forEach((nav) => {
    const menuElement = nav.querySelector('.ww-menu');

    if (nav.id === 'ww-main-menu') {
      const controllerElement = nav.querySelector('.ww-menu-toggle');
      new AccessibleMenu.DisclosureMenu({
        menuElement,
        ...menuSettings,
        controllerElement,
        containerElement: nav,
        isHoverable: true,
      });
    } else {
      const menu = new AccessibleMenu.DisclosureMenu({
        menuElement,
        ...menuSettings,
      });
    }
  });

  if (dompack.debugflags['debugmenu']) {
    for (const elDropdown of dompack.qSA('.ww-dropdown')) {
      elDropdown.classList.add('show');
    }
  }
}

function initializeMMenu() {
  if (!dompack.qS('#my-menu')) {
    return;
  }

  var $menu = new Mmenu('#my-menu', {
    navbar: {
      title: whintegration.config.site.sitetitle || ' ',
    },
    slidingSubmenus: false,
    extensions: ['pagedim-black', 'position-right'],
    counters: true,
  });

  if (whintegration.config.site.hassearchpage === true) {
    $('.mm-search').on('click', '.fa-search', function () {
      location.href = whintegration.config.site.searchpagelink + '?words=' + $('#mobile-search').val();
    });

    if ($('.search__words') !== undefined) {
      $('#mobile-search').val($('.search__words').text());
    }
  }

  // set menu to visible after init (prevents showing onload)
  const elMyMenu = dompack.qS('#my-menu');
  elMyMenu.style.opacity = 1;
  elMyMenu.style.pointerEvents = 'auto';
  elMyMenu.style.height = 'auto';

  const $toggleMmenu = $('.js-ww-toggle-mobile-menu');
  $toggleMmenu.click(function (evt) {
    evt.preventDefault();
    $menu.API.open();
  });

  if (!$menu.API) {
    console.error('mmenu not initialized');
    return;
  }

  $menu.API.bind('open:start', function () {
    setTimeout(function () {
      $toggleMmenu.addClass('is-active');
    }, 100);
  });

  $menu.API.bind('close:start', function () {
    setTimeout(function () {
      $toggleMmenu.removeClass('is-active');
    }, 100);
  });
}
