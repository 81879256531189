import './home.scss';

import * as dompack from 'dompack';
import $ from 'cash-dom';

dompack.onDomReady(() => {
  if (!document.documentElement.classList.contains('page-home')) {
    return;
  }

  $('#p-home-ctas .w-ctas__blocktitle').html(
    `<div class="c-blocktitle">
      <div class="c-blocktitle__row1">
        <span class="athelas me-2">${whintegration.config.obj.homedata.title_ctas_1}</span>
        <span>${whintegration.config.obj.homedata.title_ctas_2}</span>
      </div>
      <div class="mt-2 ms-3 mb-4 c-blocktitle__row2 athelas">
        <span class="ms-4">${whintegration.config.obj.homedata.title_ctas_3}</span>
      </div>`
  );

  $('.p-home__title').clone().prependTo('.p-home__bottomtext .w-textimage__text');

  $('.js-home-blogs .js-grid-item').each((idx, elGridItem) => {
    const bloggerImage = $(elGridItem).data('blogger_image');
    const bloggerName = $(elGridItem).data('blogger_name');
    $(elGridItem)
      .find('.w-ctas__imagelinkcontainer')
      .append(
        `<div class="c-blogger">
           <div class="c-blogger__name">${bloggerName}</div>
           <div class="c-blogger__image">
             <img src="${bloggerImage}" alt="${bloggerName}" />
           </div>
         </div>`
      );
  });
  console.log("🚀 ~ file: home.es:25 ~ $ ~ $('.js-home-blogs'):", $('.js-home-blogs'));
});
