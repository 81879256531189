// CSS-only, no JS
import './button/button.scss';
import './ctas/ctas.scss';
import './imagefullwidth/imagefullwidth.scss';
import './overview/overview.scss';
import './socials/socials.scss';
// import './photos/photos';

// has JS (which loads SCSS)
import './slideshow/slideshow';
import './textimage/textimage';
import './textvideo/textvideo';
import './twocols/twocols';
import './videoslider/videoslider';
import './photoalbum/photoalbum';

// import './button/button.scss';
// import './ctas/ctas.scss';
// import './projectcols/projectcols';
// import './verticaltabs/verticaltabs';
// import './cases/cases';
// import './paneltabs/paneltabs';
// import './overview/overview';
// import './customtext/customtext';
