/* global L */
import * as whintegration from '@mod-system/js/wh/integration';
import * as dompack from 'dompack';
import { Splide } from '@splidejs/splide';
import GLightbox from 'glightbox';

dompack.onDomReady(() => {
  if (!document.documentElement.classList.contains('page-partners')) {
    return;
  }

  setupMap();

  const customLightboxHTML = `<div id="glightbox-body" class="glightbox-container">
    <div class="gloader visible"></div>
    <div class="goverlay"></div>
    <div class="gcontainer">
    <div id="glightbox-slider" class="gslider"></div>
    <button class="gnext gbtn" tabindex="0" aria-label="Next" data-customattribute="example">{nextSVG}</button>
    <button class="gprev gbtn" tabindex="1" aria-label="Previous">{prevSVG}</button>
    <button class="gclose gbtn" tabindex="2" aria-label="Close">{closeSVG}</button>
</div>
</div>`;

  const customSlideHTML = `<div class="gslide">
    <div class="gslide-inner-content">
        <div class="ginner-container">
            <div class="gslide-media">
              <div class="gslide-description">
                  <div class="gdesc-inner">
                      <h4 class="gslide-title"></h4>
                      <div class="gslide-desc"></div>
                  </div>
              </div>
            </div>
        </div>
    </div>
</div>`;

  const sliderLightBox = GLightbox({
    selector: '.js-partners-image-carousel-lightbox',
    lightboxHTML: customLightboxHTML,
    slideHTML: customSlideHTML,
  });

  dompack.qSA('#partners-trigger-image-carousel-lightbox').forEach((el) => {
    el.addEventListener('click', (e) => {
      e.preventDefault();
      sliderLightBox.openAt(0);
    });
  });
  // lightboxVideo.on('slide_changed', ({ prev, current }) => {
  //   const { slideIndex, slideNode, slideConfig, player } = current;
  //   if (player) {
  //     if (!player.ready) {
  //       // If player is not ready
  //       player.on('ready', (event) => {
  //         // Do something when video is ready
  //       });
  //     }
  //     player.on('play', (event) => {
  //       console.log('Started play');
  //     });

  //     player.on('volumechange', (event) => {
  //       console.log('Volume change');
  //     });

  //     player.on('ended', (event) => {
  //       console.log('Video ended');
  //     });
  //   }
  // });

  if (dompack.qS('#partners-image-carousel')) {
    const slider = new Splide(dompack.qS('#partners-image-carousel'), {
      type: 'loop',
      fixedHeight: 500,
      gap: '0.5rem',
      perPage: 1,
      mediaQuery: 'min',
      autoWidth: true,
      focus: 0,
      omitEnd: true,
      // breakpoints: {
      //   768: {
      //     autoWidth: true,
      //     focus: 0,
      //     omitEnd: true,
      //     // perPage: 2,
      //     // autoWidth: true,
      //     // fixedWith: '100%',
      //   },
      //   1200: {
      //     // perPage: 3,
      //     // autoWidth: true,
      //     // fixedWith: '100%',
      //   },
      // },
    });
    slider.mount();
  }

  setTimeout(() => {
    document.documentElement.classList.add('init');
  }, 200);
});

function setupMap() {
  if (!whintegration.config.obj.location) {
    return;
  }

  const location = whintegration.config.obj.location.split(',');
  if (location.length != 2) {
    console.error(`Invalid location: ${location}`);
    return;
  }

  const locationArray = [location[0], location[1]];

  const map = L.map('map').setView(locationArray, 14);

  L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: 19,
    attribution: '',
  }).addTo(map);

  L.marker(locationArray).addTo(map);
}
